import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./desktopFeatureSlider.module.css"
import { graphql } from "gatsby"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
// import Responsive from "react-responsive"
import Img from "gatsby-image"

import { Fragment } from "react"
import { Location } from "@reach/router"

// const Desktop = props => <Responsive {...props} minWidth={1130}/>
// const Tablet = props => <Responsive {...props} minWidth={979} maxWidth={1130}/>
// const Mobile = props => <Responsive {...props} maxWidth={978}/>
// const Default = props => <Responsive {...props} minWidth={768}/>

class DesktopFeatureSlider extends React.Component{

  render(){

    return (

      <StaticQuery
        query={graphql`query DesktopFeatureSlider {
  allContentfulFeatureSlider {
    edges {
      node {
        id
        subtitle
        title
        description
        image {
          fluid(quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          title
        }
        feature {
          ordinal
          title
        }
      }
    }
  }
}
`}
        render={data => {
          return (
            <FeatureSlider data={data} {...this.props} />
          )
        }
        }
      />

    )
  }
}

class FeatureSlider extends React.Component{
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {};
  }
  componentDidMount(){
    var url = new URL(this.props.location.href);
    var ord = url.searchParams.get("ordinal");

    if(ord){
      this.setState({ current: parseInt(ord) });
    }
    else{
      this.setState({ current: 1 });
    }


  }

  getCurrent(){
    return this.state.current;
  }

  render(){
    const { data } = this.props;
    let nextOrdinal = this.state.current + 1;
    let prevOrdinal = this.state.current - 1;
    const allOrdinals = data.allContentfulFeatureSlider.edges.map(elm=> elm.node.feature.ordinal);

    if (allOrdinals.filter(elm => elm === nextOrdinal).length === 0){
      nextOrdinal = undefined;
    }
    if (allOrdinals.filter(elm => elm === prevOrdinal).length === 0){
      prevOrdinal = undefined;
    }

    let nextText = undefined;
    let prevText = undefined;

    if (nextOrdinal){
      nextText = data.allContentfulFeatureSlider.edges.filter(elm => elm.node.feature.ordinal === nextOrdinal)[0].node.title;
    }
    if (prevOrdinal){
      prevText = data.allContentfulFeatureSlider.edges.filter(elm => elm.node.feature.ordinal === prevOrdinal)[0].node.title;
    }

    return (
      <Fragment>
          <div id='FeatureSliderDesktop'className={styles.desktopFeatureSlider}>
            {
              prevOrdinal &&
              <div
                onClick={()=>{
                  this.setState({current: this.state.current - 1})
                }}
                className={styles.prevButton}>
                <div className={styles.iconContainer}>
                  <FaArrowLeft className={styles.arrowIcon} size={16} />
                  <p className={styles.prevPlaceHolder}> { prevText } </p>
                </div>
              </div>
            }
            {data.allContentfulFeatureSlider.edges.map(elm =>{
              const current = elm.node;
              if(current.feature.ordinal === this.state.current){
                return (
                  <div className={`${styles.slide} ${styles.visible}`}>
                    <div className={styles.leftContent}>
                      <h1 className={styles.title}>{current.title}</h1>
                      <h2 className={styles.subtitle}>{current.subtitle}</h2>
                      <p className={styles.description}>
                        {current.description}
                      </p>
                    </div>
                    <div className={styles.rightContent}>
                      { current.image && current.image !== null &&  <Img fluid={current.image.fluid}/>}
                    </div>
                  </div>
                )
              }
              else{
                return (
                  <div className={`${styles.slide} ${styles.hidden}`}>
                    <div className={styles.leftContent}>
                      <h1 className={styles.title}>{current.title}</h1>
                      <h2 className={styles.subtitle}>{current.subtitle}</h2>
                      <p className={styles.description}>
                        {current.description}
                      </p>
                    </div>
                    <div className={styles.rightContent}>
                      { current.image && current.image !== null &&  <Img fluid={current.image.fluid}/>}
                    </div>
                  </div>
                )
              }


            })}
            {
              nextOrdinal &&

              <div onClick={()=>{
                this.setState({current: this.state.current + 1})
              }} className={styles.nextButton}>
                <div className={styles.iconContainer}>
                  <FaArrowRight className={styles.arrowIcon} size={16} />
                  <p className={styles.nextPlaceholder}> { nextText } </p>
                </div>
              </div>
            }

          </div>
      </Fragment>
    )
  }
}

DesktopFeatureSlider.propTypes = {}

DesktopFeatureSlider.defaultProps = {}

export default props => {
  return (
    <Location>
      {locationProps => {
        return (
          <DesktopFeatureSlider {...locationProps} {...props} />
        )
      }}
    </Location>
  );
}
