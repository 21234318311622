import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./mobileFeatureSlider.module.css"
import { graphql } from "gatsby"
// import Responsive from "react-responsive"
import Img from "gatsby-image"

import { Fragment } from "react"

// const Desktop = props => <Responsive {...props} minWidth={1130}/>
// const Tablet = props => <Responsive {...props} minWidth={979} maxWidth={1130}/>
// const Mobile = props => <Responsive {...props} maxWidth={978}/>
// const Default = props => <Responsive {...props} minWidth={768}/>

class MobileFeaturesSlider extends React.Component{

  render(props){

    return (

      <StaticQuery
        query={graphql`query MobileFeatureSlider {
  allContentfulFeatureSlider {
    edges {
      node {
        id
        subtitle
        title
        description
        image {
          fluid(quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          title
        }
        feature {
          ordinal
          title
        }
      }
    }
  }
}
`}
        render={data => {
          return (
            <FeatureSlider data={data} {...props} />
          )
        }
        }
      />

    )
  }
}

class FeatureSlider extends React.Component{
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { current: 1 };
  }
  componentDidMount(){
    this.setState({ current: 1 });
  }

  getCurrent(){
    return this.state.current;
  }

  render(){
    const { data } = this.props;
    let nextOrdinal = this.state.current + 1;
    let prevOrdinal = this.state.current - 1;
    const allOrdinals = data.allContentfulFeatureSlider.edges.map(elm=> elm.node.feature.ordinal);

    if (allOrdinals.filter(elm => elm === nextOrdinal).length === 0){
      nextOrdinal = undefined;
    }
    if (allOrdinals.filter(elm => elm === prevOrdinal).length === 0){
      prevOrdinal = undefined;
    }

    // let nextText = undefined;
    // let prevText = undefined;

    // if (nextOrdinal){
    //   nextText = data.allContentfulFeatureSlider.edges.filter(elm => elm.node.feature.ordinal === nextOrdinal)[0].node.title;
    // }
    // if (prevOrdinal){
    //   prevText = data.allContentfulFeatureSlider.edges.filter(elm => elm.node.feature.ordinal === prevOrdinal)[0].node.title;
    // }

    return (
      <Fragment>
          <div  id='FeatureSliderMobile' className={styles.mobileFeatureSlider}>

            {data.allContentfulFeatureSlider.edges.map((elm, i) =>{
              const current = elm.node;
                let styleElm = styles.even;
                if (i % 3 === 0){
                  styleElm = styles.third;
                }
                else if( i%3 === 1){
                  styleElm = styles.odd
                }
                return (
                  <div className={`${styles.slide} ${styleElm}`}>
                    <div className={styles.leftContent}>
                      <h1 className={styles.title}>{current.title}</h1>
                      <h2 className={styles.subtitle}>{current.subtitle}</h2>
                      <p className={styles.description}>
                        {current.description}
                      </p>
                    </div>
                    <div className={styles.rightContent}>
                      { current.image && current.image !== null &&  <Img fluid={current.image.fluid}/>}
                    </div>
                  </div>
                )


            })}
          </div>
      </Fragment>
    )
  }
}
MobileFeaturesSlider.propTypes = {}

MobileFeaturesSlider.defaultProps = {}
export default MobileFeaturesSlider
