import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./productTitleBlock.module.css"
import { graphql } from "gatsby"
import { FaArrowDown } from "react-icons/fa"
import { Fragment } from "react"

const ProductTitleBlock = () => {
  return (

    <StaticQuery
      query={graphql`query ProductTitle {
  allContentfulProductTitleBlock {
    nodes {
      line1
      id
      viewMore
      updatedAt
    }
  }
}                  `}
      render={data => {
        return (
          <Fragment>

              <div className={styles.productTitleBlock}>
                <h1 className={styles.title}>{data.allContentfulProductTitleBlock.nodes[0].line1}</h1>
                <p  onClick={()=>{
                  if(typeof document !== "undefined"){
                    document.getElementById('FeatureSliderMobile').scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }

                }}
                    className={styles.findMore}>
                  <FaArrowDown size={16} className={styles.iconMore}/>
                  <span> Check some of our <strong>core features</strong> below</span>
                </p>
              </div>



              <div className={styles.productTitleBlockDesktop}>
                <h1 className={styles.title}>{data.allContentfulProductTitleBlock.nodes[0].line1}</h1>
                <p onClick={()=>{
                  if(typeof document !== "undefined"){
                    document.getElementById('FeatureSliderDesktop').scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }

                }}
                   className={styles.findMore}>
                  <FaArrowDown size={16} className={styles.iconMore}/>
                 <span> Check some of our <strong>core features</strong> below</span>
                </p>
              </div>


              <div className={styles.productTitleBlockTablet}>
                <h1 className={styles.title}>{data.allContentfulProductTitleBlock.nodes[0].line1}</h1>
                <p onClick={()=>{
                  if(typeof document !== "undefined"){
                    document.getElementById('FeatureSlider').scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }

                }}
                   className={styles.findMore}>
                  <FaArrowDown size={16} className={styles.iconMore}/>
                  <span>Check some of our <strong>core features</strong> below</span>
                </p>
              </div>

          </Fragment>
        )
      }
      }
    />

  )
}

ProductTitleBlock.propTypes = {}

ProductTitleBlock.defaultProps = {}
export default ProductTitleBlock
