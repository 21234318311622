import { StaticQuery } from "gatsby"
import React from "react"
import { graphql } from "gatsby"
import styles from './featuresSliderBlock.module.css'
import { Fragment } from "react"
import DesktopFeatureSlider from "../DesktopFeaturesSlider/DesktopFeatureSlider"
import MobileFeaturesSlider from "../MobileFeaturesSlider/MobileFeaturesSlider"

const FeaturesSliderBlock = (props) => {
  return (

    <StaticQuery
      query={graphql`query FeaturesSlider {
  allContentfulProductTitleBlock {
    nodes {
      line1
      id
      viewMore
      updatedAt
    }
  }
}                  `}
      render={data => {

        return (
          <Fragment>
            <div className={styles.mobile}>
              <MobileFeaturesSlider {...props}/>
            </div>

            <div className={styles.desktop}>
              <DesktopFeatureSlider {...props}/>
            </div>

            <div className={styles.tablet}>
              <DesktopFeatureSlider {...props}/>
            </div>

          </Fragment>
        )
      }
      }
    />

  )
}

FeaturesSliderBlock.propTypes = {}

FeaturesSliderBlock.defaultProps = {}
export default FeaturesSliderBlock
