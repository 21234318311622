import React from "react"

import Layout from "../components/layout"
import ProductTitleBlock from "../components/ProductTitleBlock/ProductTitleBlock.js"
import FeaturesSliderBlock from "../components/FeaturesSliderBlock/FeaturesSliderBlock.js"
import ContactBlock from "../components/ContactBlock/ContactBlock"

const IndexPage = () => (
  <Layout>
    <ProductTitleBlock/>
    <FeaturesSliderBlock/>
    <ContactBlock/>
  </Layout>
)

export default IndexPage
